<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          Points Issued Records
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-date-picker
          type="date"
          placeholder="Start Issued Time"
          v-model="query.startCreatedTime"
          class="handle-input"
          style="margin-right: 10px"
        ></el-date-picker>
        <el-date-picker
          type="date"
          placeholder="End Issued Time"
          v-model="query.endCreatedTime"
          class="handle-input"
          style="margin-right: 10px"
        ></el-date-picker>
        <el-select
          v-model="query.name"
          value-key="name"
          placeholder="Point Type"
          class="mr10"
          :clearable="true"
        >
          <el-option
            v-for="item in options.pointsTemplateList"
            :key="item"
            :label="item.title"
            :value="item.name"
          ></el-option>
        </el-select>
        <el-input
          v-model="query.studentId"
          placeholder="Student No."
          class="handle-input mr10"
          @keyup.enter="handleSearch"
          :clearable="true"
        ></el-input>
        <el-button type="primary" @click="handleSearch">
          <el-icon>
            <search />
          </el-icon>
          Search
        </el-button>
        <el-button type="primary" @click="openIssuePointsDialog" style="float: right">
          <el-icon> <plus /> </el-icon>
          Issue Points
        </el-button>
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @sort-change="changeSort"
        :default-sort="{ prop: 'CreatedTime', order: 'DESC' }"
      >
        <el-table-column
          prop="title"
          label="Title"
          :width="this.$widthRatio * 320 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="studentId"
          label="Student No."
          :width="this.$widthRatio * 90 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="studentName"
          label="Student Name"
          :width="this.$widthRatio * 150 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="points"
          label="Points"
          :width="this.$widthRatio * 100 + 'px'"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="Points"
        >
          <template #default="scope">
            {{ scope.row.points
            }}{{ scope.row.issuedType === "Normal" ? "" : ` (${scope.row.issuedType})` }}
          </template>
        </el-table-column>
        <el-table-column prop="comment" label="Comment"></el-table-column>
        <el-table-column
          prop="createdTime"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="CreateTime"
          label="Issued Time"
          :width="this.$widthRatio * 120 + 'px'"
          :formatter="$tableCellFormatter.formatDate"
        ></el-table-column>
        <el-table-column
          label="Operation"
          :width="this.$widthRatio * 100 + 'px'"
          align="center"
        >
          <template #default="scope">
            <el-button
              type="text"
              class="red"
              @click="handleDelete(scope.$index, scope.row)"
            >
              <el-icon>
                <delete />
              </el-icon>
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="itemTotal"
          :page-count="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>

      <el-dialog :title="addPointsDialogTitle" v-model="showAddPointsDialog" width="60%">
        <add-points :initData="{ callback: issuedPointsCallback }" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getData, postData, deleteData } from "../../service/api";
import AddPoints from "./AddPoints.vue";

export default {
  components: { AddPoints },
  name: "pointsRecordList",
  data() {
    return {
      controllerUrl: "/points",
      query: {
        studentId: null,
        name: null,
        startCreatedTime: null,
        endCreatedTime: null,
        orderBy: "CreatedTime",
        orderDirection: "DESC",
        pageIndex: 1,
        pageSize: 20,
      },
      options: {
        pointsTemplateList: [],
      },
      tableData: [],
      showAddPointsDialog: false,
      addPointsDialogTitle: "",
      itemTotal: 0,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
    };
  },
  created() {
    this.options.pointsTemplateList = this.$appSetting.globalConfig.pointsTemplateList;

    this.initAndLoadData();
  },
  methods: {
    initAndLoadData() {
      this.loadData();
    },
    loadData() {
      getData(this.controllerUrl, this.query).then((res) => {
        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    changeSort(sortData) {
      console.log(sortData);
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection = sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    issuedPointsCallback(res) {
      console.log("issuedPointsCallback", res);
      if (res.result && res.code === "200") {
        this.showAddPointsDialog = false;
        this.loadData();
      }
    },
    handleDelete(index, row) {
      // twice to confirm to delete
      this.$confirm("Are you sure to delete this item?", "Prompt", {
        type: "warning",
      })
        .then(() => {
          deleteData(this.controllerUrl, row.recordId, {
            studentId: row.studentId,
          }).then((res) => {
            if (res.result && res.code === "200") {
              this.$message.success("Delete successfully");
              this.tableData.splice(index, 1);
            } else {
              this.$message.error("Delete failed, error message: " + res.message);
            }
          });
        })
        .catch(() => {});
    },
    openIssuePointsDialog() {
      this.form = {};
      this.addPointsDialogTitle = "Issue Points";
      this.showAddPointsDialog = true;
    },
    saveEdit() {
      this.$refs.mainForm.validate((valid) => {
        if (valid) {
          postData(this.controllerUrl, this.form).then((res) => {
            console.log(res);
            if (res.result && res.code === "200") {
              this.editVisible = false;
              this.$message.success("Create successfully");
              this.form = res.result;
              this.loadData();
            } else {
              this.$message.error("Create failed, error message: " + res.message);
            }
          });
        } else {
          this.$message.error("Validation failed, please correct!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 1.17rem;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
